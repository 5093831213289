export const hex2rgba = (hex: string | undefined, opacity: number): string => {
    if (!hex) {
        return '';
    }
    if (/^#?([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        const [r, g, b] = hex2rgbArray(hex);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    } else {
        return hex;
    }
};

type HexArray = [string, string, string] | string[];

export const hex2rgbArray = (hex: string): number[] => {
    let c = hex.replace(/^#/, '').split('') as HexArray;
    if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    const color = parseInt(c.join(''), 16) || 0;

    const r = (color >> 16) & 255;
    const g = (color >> 8) & 255;
    const b = color & 255;

    return [r, g, b];
};
